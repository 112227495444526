























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

@Component({
  components: {
    Swiper,
    SwiperSlide,
  },
})
export default class TabsCommon extends Vue {
  @Prop({ type: Array })
  tabs?: string[]

  selectedTab = 0

  $refs!: {
    slider: HTMLDivElement
  }

  setSlotName(index: number): string {
    return `tab-${index + 1}`
  }

  async handleSelectTab(id: number, tab: string): Promise<void> {
    this.selectedTab = id
    await this.$nextTick()
    this.getSliderStyle()
    this.$emit('tab-click', { id, tab })
  }

  getSliderStyle(): void {
    const tabElem = this.$el.querySelector(
      '.tabs-common__item--active'
    ) as HTMLDivElement
    if (tabElem) {
      this.$refs.slider.style.width = `${tabElem.clientWidth}px`
      this.$refs.slider.style.transform = `translateX(${tabElem.offsetLeft}px)`
    }
  }

  slideMove(): void {
    this.getSliderStyle()
  }

  changeTab(id: number): void {
    this.selectedTab = id
  }

  mounted(): void {
    this.getSliderStyle()
  }
}
